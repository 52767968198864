import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from 'components/layout'

import '../scss/style.scss'

const HeaderLineAbout = () => {
  return (
    <div class="header bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="display-4 pagetitle">About Us</p>
            <p class="lead my-4">
              Relation Art was founded in 2019. It has been built on experience,
              expertise, research and passion to guide individuals in finding
              their purpose.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const CardBlock = data => {
  return (
    <section class="container pt-5 pb-5">
      <div class="row">
        <div class="card-deck">
          <div class="card no-hover">
            <div class="card-header">
              <Img
                fluid={data.vision.childImageSharp.fluid}
                alt="Vision"
                className="rounded shadow-lg"
              />
            </div>
            <div class="card-body">
              <h4 class="card-title mt-2">Vision</h4>
              <p class="card-text">
                Our vision is to be the life coaching partner of choice,
                providing professionalism and quality services enabling growth,
                change, and success, which is applicable to individuals,
                couples, families and organisations.
              </p>
            </div>
          </div>

          <div class="card no-hover">
            <div class="card-header">
              <Img
                fluid={data.goal.childImageSharp.fluid}
                alt="Goal"
                className="rounded shadow-lg"
              />
            </div>
            <div class="card-body">
              <h4 class="card-title mt-2">Goal</h4>
              <p class="card-text">
                Our goal is to guide and assist as many people as possible
                through life coaching. We want you to understand that you matter
                and that you are not alone. You, have the support that you need,
                you are worthy and you have a purpose on this journey called
                "life".
              </p>
            </div>
          </div>

          <div class="card no-hover">
            <div class="card-header">
              <Img
                fluid={data.values.childImageSharp.fluid}
                alt="Values"
                className="rounded shadow-lg"
              />
            </div>
            <div class="card-body">
              <h4 class="card-title mt-2">Values</h4>
              <p class="card-text">Our values are...</p>
              <ul class="list-unstyled">
                <li>
                  <i class="fa fa-check text-primary mx-2"></i>
                  To guide people in improving various areas of their life.
                </li>
                <li>
                  <i class="fa fa-check text-primary mx-2"></i>
                  To be ethical and professional in all process.
                </li>
                <li>
                  <i class="fa fa-check text-primary mx-2"></i>
                  To provide valued services and build effective relationships.
                </li>
                <li>
                  <i class="fa fa-check text-primary mx-2"></i>
                  To ensure that the process is positive and tailored to suit
                  the need of the person.
                </li>
                <li>
                  <i class="fa fa-check text-primary mx-2"></i>
                  To establish an interactive session of coaching based on
                  commitment and trust.
                </li>
                <li>
                  <i class="fa fa-check text-primary mx-2"></i>
                  To motivate and facilitate clients in achieving their true
                  potential in order to bring about positive change.
                </li>
                <li>
                  <i class="fa fa-check text-primary mx-2"></i>
                  To maintain a high level of integrity and unbiased opinion of
                  people regardless of gender, ethnicity, stability, health or
                  background.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ({ data, location }) => (
  <Layout location={location} crumbLabel="About">
    <SEO site={get(data, 'site.meta')} />
    {HeaderLineAbout()}
    {CardBlock(data)}
  </Layout>
)

export const query = graphql`
  query {
    vision: file(relativePath: { eq: "green-4712232_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    goal: file(relativePath: { eq: "checklist-2589418_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    values: file(relativePath: { eq: "mom-2208928_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
